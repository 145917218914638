import styled from 'styled-components'
import { Spacings, Text } from '@styles'
import { Button } from '@base'
import { Delete } from '@material-ui/icons'

const Wrapper = styled.div`
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	border-radius: ${Spacings.SPACING_2B};
	width: 100%;
	height: 100%;
`

const Item = styled.span`
	font-size: ${({ uppercase }) => (uppercase ? Text.SMALL : Text.MEDIUM)};
	display: inline-block;
	color: ${({ theme }) => theme.palette.text.darkblack};
	margin-bottom: ${({ marginBottom }) => (marginBottom ? Spacings.SPACING_1B : 0)};
	text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : '')};
	font-weight: ${({ uppercase }) => (uppercase ? '600' : '500')};
`

const AddressHead = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const IconWrapper = styled(Button)`
	padding: 0 ${Spacings.SPACING_2B};
	height: ${Spacings.SPACING_6B};
	width: ${Spacings.SPACING_6B};
	min-width: 0;
	margin: 0 ${Spacings.SPACING_1B};
	min-height: 0;
`

const AddressCard = ({ data, showAddress = true, showDelete = false, callback = null }) => {
	return (
		<Wrapper>
			{showAddress && (
				<AddressHead>
					<Item marginBottom>{data?.complete_address}</Item>
					<br />
					{showDelete && (
						<IconWrapper small type='secondary' variant='destructive' onClick={callback}>
							<Delete fontSize='small' />
						</IconWrapper>
					)}
				</AddressHead>
			)}
			<Item uppercase>{data?.city},&#160;</Item>
			<Item uppercase>{data?.state}&#160;-&#160;</Item>
			<Item uppercase>{data?.pincode}</Item>
		</Wrapper>
	)
}

export default AddressCard
