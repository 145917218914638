import styled, { css } from 'styled-components'
import { Spacings, Text, Devices } from '@styles'

export const Wrapper = styled.div`
	display: flex;
	width: 100vw;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1001;
	display: flex;
	flex-direction: column;
`

export const ViewerWrapper = styled.div`
	flex-grow: 1;
	background: ${({ theme }) => theme.palette.background.dark + 'cc'};
	max-height: 75vh;
	padding: ${Spacings.SPACING_8B} 0rem;
	@media ${Devices.mobile} {
		max-height: calc(100vh - ${Spacings.SPACING_15B});
		padding: 0rem;
	}
`
export const ViewerFooter = styled.div`
	width: 100%;
	min-height: ${Spacings.SPACING_15B};
	max-height: ${Spacings.SPACING_15B};
	background: ${({ theme }) => theme.palette.background.dark};
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Tool = styled.div`
    height: ${Spacings.SPACING_8B};
    width:  ${Spacings.SPACING_8B};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${({ disabled, theme }) => (disabled ? theme.palette.background.light : theme.palette.background.white)};
    & > svg {
        fill: background: ${({ theme }) => theme.palette.text.dark};
    }

    margin: 0 ${Spacings.SPACING_1B};
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};

	${({ isText }) => {
		if (isText) {
			return css`
				width: fit-content;
				border-radius: 5rem;
				padding: 0 ${Spacings.SPACING_2B};
				font-size: ${Text.SMALL};
				background: ${({ theme }) => theme.palette.background.green};
				@media ${Devices.tablet} {
					font-size: ${Text.MEDIUM};
				}
			`
		}
	}}
`

export const ZoomLevel = styled.div`
	color: ${({ theme }) => theme.palette.text.white};
	font-size: ${Text.SMALL};
	margin: 0 ${Spacings.SPACING_1B};
	min-width: ${Spacings.SPACING_9B};
	text-align: center;
`

export const NavigationButton = styled(Tool)`
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				margin-right: auto;
				margin-left: ${Spacings.SPACING_4B};
				@media ${Devices.tablet} {
					margin-right: ${Spacings.SPACING_1B};
				}
			`
		}
		return css`
			margin-left: auto;
			margin-right: ${Spacings.SPACING_4B};
			@media ${Devices.tablet} {
				margin-left: ${Spacings.SPACING_1B};
			}
		`
	}}
`

export const CloseTool = styled(Tool)`
	position: absolute;
	top: ${Spacings.SPACING_2B};
	right: ${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.background.dark};
	& > svg {
		fill: ${({ theme }) => theme.palette.text.white};
	}
	z-index: 1001;
`

export const ScalingArea = styled.div`
	max-height: 100%;
	max-width: 100%;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 ${Spacings.SPACING_4B};

	overflow: hidden;
`
export const RenderingArea = styled.div`
	max-height: 100%;
	height: 100%;
	cursor: move;
	transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transform-origin: center;
`

export const ToolbarWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const PdfWrapper = styled.div`
	overflow: scroll;
	max-width: 100%;
	max-height: 100%;

	.rpv-core-inner-page {
		background: transparent !important;
		max-width: 100% !important;
		display: flex;
		justify-content: center;
	}
`
